import {
  _SET_BOOKINGS_COUNT,
  _SET_BOOKINGS_MAP,
  _SET_BOOKINGS_REVIEWS,
  _SET_BOOKING_QUOTE,
  _SET_BOOKING_QUOTE_LOADING,
  _SET_ERROR,
  _SET_POSTAL_CODE,
  _SET_QUOTE_ID
} from "./mutations"
import { createBookingInsightsApi } from "~/services/public/BookingInsightsApi"

export const FETCH_BOOKINGS_COUNT = "fetchBookingsCount"
export const FETCH_BOOKINGS_MAP = "fetchBookingsMap"
export const FETCH_BOOKINGS_REVIEWS = "fetchBookingsReviews"
export const FETCH_BOOKING_QUOTE = "fetchBookingQuote"
export const SET_POSTAL_CODE = "setPostalCode"
export const SET_QUOTE_ID = "setQuoteId"

export default {
  async [FETCH_BOOKINGS_COUNT]({ commit }, { $axios, $config, params }) {
    commit(_SET_ERROR, null)

    try {
      const api = createBookingInsightsApi($axios, $config)
      const response = await api.getBookingsCount(params)
      const transformedData = {
        num_jobs_year: response.data.total_bookings,
        cheeky_title: response.data.meta.associatedTitle,
        short_postcode: response.data.meta.shortPostalCode,
        average_four_bed_home_move_cost: response.data.meta.averageFourBedHomeMoveCost,
        average_home_move_cost: response.data.meta.averageHomeMoveCost,
        average_number_of_moves_by_agent: response.data.meta.averageNumberOfMovesByAgent,
        average_savings: response.data.meta.averageSavings,
        faq_average_cost: response.data.meta.faqAverageCost,
        faq_move_near_me: response.data.meta.faqMoveNearMe,
        location: response.data.location,
        currency: response.data.meta.currency,
        number_of_quotes_in_area: response.data.meta.numberOfQuotesInArea
      }
      commit(_SET_BOOKINGS_COUNT, transformedData)
      return true
    } catch (error: unknown) {
      const err = error as Error
      commit(_SET_ERROR, err.message)
      return err
    }
  },

  async [FETCH_BOOKINGS_MAP]({ commit }, { $axios, $config, params }) {
    commit(_SET_ERROR, null)

    try {
      const api = createBookingInsightsApi($axios, $config)
      const response = await api.getBookingsMap(params)
      const transformedData: AvPinData[] = response.data.pins.map(pin => ({
        lat: pin.latitude,
        lng: pin.longitude,
        id: pin.id,
        imageID: pin.meta.imageID,
        stars: pin.meta.stars,
        textLine1: pin.meta.bookingDescription,
        textLine2: pin.meta.distance,
        textLine3: pin.meta.price
      }))
      commit(_SET_BOOKINGS_MAP, { pins: transformedData })
      return true
    } catch (error: unknown) {
      const err = error as Error
      commit(_SET_ERROR, err.message)
    }
  },

  async [FETCH_BOOKINGS_REVIEWS]({ commit }, { $axios, $config, params }) {
    commit(_SET_ERROR, null)

    try {
      const api = createBookingInsightsApi($axios, $config)
      const response = await api.getBookingsReviews(params)
      const transformedData: InternalReview[] = response.data.reviews.map(review => ({
        id: review.id,
        name: review.reviewerName,
        rating: review.rating,
        type: review.reviewDescription || "home",
        location: review.address,
        shortPostalCode: review.meta.shortPostalCode,
        typeEmoji: "🏡",
        review: review.reviewComment
      }))
      commit(_SET_BOOKINGS_REVIEWS, transformedData)
      return true
    } catch (error: unknown) {
      const err = error as Error
      commit(_SET_ERROR, err.message)
    }
  },

  async [FETCH_BOOKING_QUOTE]({ commit }, { $axios, $config, params }) {
    commit(_SET_BOOKING_QUOTE_LOADING, true)
    commit(_SET_ERROR, null)

    try {
      const api = createBookingInsightsApi($axios, $config)
      const response = await api.getBookingQuote(params)

      const quoteData = <BookingQuote>{
        quoteId: response.data.quoteId,
        quotePrice: response.data.quotePrice,
        quoteDescription: response.data.quoteDescription,
        quoteCurrency: response.data.quoteCurrency,
        quoteStatus: response.data.quoteStatus,
        scheduledDate: response.data.scheduledDate,
        continueQuoteUrl: response.data.continueQuoteUrl || "",
        locations: response.data.locations || {},
        items: response.data.items || [],
        agentInteraction: { ...response.data.agentInteraction }
      }
      commit(_SET_BOOKING_QUOTE, quoteData)
      return true
    } catch (error: unknown) {
      const err = error as Error
      commit(_SET_ERROR, err.message)
      return err
    } finally {
      commit(_SET_BOOKING_QUOTE_LOADING, false)
    }
  },

  async [SET_POSTAL_CODE]({ commit }, { params }) {
    commit(_SET_POSTAL_CODE, params)
  },

  async [SET_QUOTE_ID]({ commit }, { params }) {
    commit(_SET_QUOTE_ID, params)
  }
}
